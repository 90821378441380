import { Injectable } from '@angular/core';
import { NuarArchitectureService, NuarHttpMethod } from '@nuarsanesp/nuar-service-library';
import { Observable, of, forkJoin, Subject } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CaaSService {

  private readonly headerContentSource = new Subject();
  public headerContent$ = this.headerContentSource.asObservable();
  private readonly footerContentSource = new Subject();
  public footerContent$ = this.footerContentSource.asObservable();
  private readonly faqsContentSource = new Subject();
  public faqsContent$ = this.faqsContentSource.asObservable();
  private readonly areasContentSource = new Subject();
  public areasContent$ = this.areasContentSource.asObservable();
  private readonly testimoniosContentSource = new Subject();
  public testimoniosContent$ = this.testimoniosContentSource.asObservable();
  private readonly galleriesContentSource = new Subject();
  public galleriesContent$ = this.galleriesContentSource.asObservable();
  private readonly currentPageSource = new Subject();
  public currentPage$ = this.currentPageSource.asObservable();

  private readonly caasConfig = this.architectureService.getConfigProperty('caas');
  public biblioteca: string = this.caasConfig.biblioteca;
  public apiURL = `${this.caasConfig.baseUrl}${this.caasConfig.basePath}/${this.caasConfig.biblioteca}/`;
  private readonly caasCommonContent: object = {};
  private readonly method: NuarHttpMethod = NuarHttpMethod.get;


  /**
   * constructor
   * @param architectureService architectureService
   */
  constructor(private readonly architectureService: NuarArchitectureService) { }

  /**
   * reMap
   * @param response
   * @returns
   */
  private reMap(response) {
    this.recursiveClean(response);

    response.sections.forEach(s => {
      const newSectionContents = [];
      s.section_contents.forEach(c => {
        if (c.sections && !c.main_content) {
          newSectionContents.push({
            main_content: {
              order: c.order,
              name: c.name,
              description: c.description,
              title: c.title,
              antetitle: c.antetitle,
              entradilla: c.entradilla,
              body: c.body,
              link: c.link,
              image: c.image
            },
            sections: c.sections
          });
        } else { newSectionContents.push(c); }
      });
      s.section_contents = newSectionContents;
    });
    return response;
  }

  /**
   * recursiveClean
   * @param obj
   * @param parentKey
   */
  private recursiveClean(obj, parentKey = null) {
    Object.keys(obj).forEach(k => {
      // Change string 'null' to real null
      if (obj[k] === 'null' || obj[k] === '' || obj[k] === null || obj[k] === undefined) {
        obj[k] = undefined;
        return;
      }

      // Remove <p> container tag from texts
      if (['title', 'body', 'entradilla', 'antentitle'].indexOf(k) !== -1 && obj[k].substring(0, 2) === '<p') {
        obj[k] = obj[k].substring(13, obj[k].length - 5);
      }
      switch (k) {
        // urls
        case 'url':
        case 'url_mobile':
        case 'url_tablet':
        case 'url_desktop':
          let img = obj[k] || '';
          if (!!img.match(/(.png|.jpg)/gi)) {
            img = `${this.caasConfig.baseUrl}${this.caasConfig.imagesPath}${img}`;
          }
          obj[k] = img;
          break;
        // Convert string order numbers to real numbers
        case 'order':
        case 'section_order':
          obj[k] = parseInt(obj[k], 10);
          break;
        // Remove numbers characters from section_name
        case 'section_name':
          obj[k] = obj[k].replace(/[0-9]/g, '');
          break;
        // Recursive clean in objects arrays
        case 'sections':
        case 'section_contents':
          obj[k].forEach(e => this.recursiveClean(e));
          break;
      }

      // Recursive clean in objects
      if (typeof obj[k] === 'object' && !Array.isArray(obj[k])) {
        this.recursiveClean(obj[k], k);
      }
    });
  }

  public getRESTData(resource: string) {
    const url = `${this.apiURL}${resource}${this.caasConfig.params}`;
    this.architectureService.showSpinner();
    return this.architectureService
      .connectorAsyncApi(url, this.method, {})
      // .connectorAsyncApi(url, this.method, {withCredentials: true})
      .pipe(
        map(res => {
          this.architectureService.hideSpinner();
          if (res.length === 0) {
            return res;
          }
          if (Array.isArray(res)) {
            return this.reMap(res[0]);
          } else {
            return this.reMap(res);
          }
        }),
        shareReplay()
      );
  }

  public getPage(options: any): void {
    const page = options.page;
    this.getRESTData(`${this.caasConfig.area_contenidos}/${page}/${page}`)
      .subscribe(resp => this.currentPageSource.next(resp));
  }

  public getMainContent(options: any): Observable<any> {
    const page = options.page.toLowerCase();
    return of(this.caasCommonContent[`${this.biblioteca}-${page}`]);
  }

  public setLibrary(lang) {
    this.biblioteca = this.caasConfig[`biblioteca_${lang}`];
    this.apiURL = `${this.caasConfig.baseUrl}${this.caasConfig.basePath}/${this.biblioteca}/`;
  }

  public loadAllContent = (): any => {
    if (Object.keys(this.caasCommonContent).length === 0) {
      return forkJoin(
        this.getRESTData(`${this.caasConfig.area_temas}/header/header`),
        this.getRESTData(`${this.caasConfig.area_temas}/footer/footer`),
        this.getRESTData(`${this.caasConfig.area_temas}/faqs/faqs`),
        this.getRESTData(`${this.caasConfig.area_temas}/areas/areas`),
        this.getRESTData(`${this.caasConfig.area_temas}/testimonios/testimonios`),
        this.getRESTData(`${this.caasConfig.area_temas}/galleries/galleries`)
      ).pipe(
        tap(([header, footer, faqs, areas, testimonios, galleries]) => {
          this.headerContentSource.next(header);
          this.footerContentSource.next(footer);
          this.faqsContentSource.next(faqs);
          this.areasContentSource.next(areas);
          this.testimoniosContentSource.next(testimonios);
          this.galleriesContentSource.next(galleries);

          this.caasCommonContent[`${this.biblioteca}-header`] = header;
          this.caasCommonContent[`${this.biblioteca}-footer`] = footer;
          this.caasCommonContent[`${this.biblioteca}-faqs`] = faqs;
          this.caasCommonContent[`${this.biblioteca}-areas`] = areas;
          this.caasCommonContent[`${this.biblioteca}-testimonios`] = testimonios;
          this.caasCommonContent[`${this.biblioteca}-galleries`] = galleries;
        })
      );
    } else {
      return of(true);
    }
  }

}
