import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: 'app/modules/main-container/main-container.module#MainContainerModule',
    data: {
      seo: {
        title: `Portal Empleo`,
        meta: {
          description: `Portal Empleo Banco Santader`,
          image: '/assets/images/logo_santander.png'
        }
      }
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SempRoutingModule { }
