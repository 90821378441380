import { NuarArchitectureService } from '@nuarsanesp/nuar-service-library';
import { TealiumUtagService } from './core/services/utag.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'semp-root',
  templateUrl: './semp.component.html',
  providers: [TealiumUtagService],
})
export class SempComponent implements OnInit {
  // Properties
  title = 'santander-portal-de-empleo';
  ssoToken: any;

  // - Privates
  private readonly tealiumConfig = this.architectureService.getConfigProperty('tealium').config;


  /**
   * constructor
   * @param tealium
   * @param architectureService
   */
  constructor(
    private readonly tealium: TealiumUtagService,
    private readonly architectureService: NuarArchitectureService) {
    this.tealium.setConfig(this.tealiumConfig);
  }

  ngOnInit(): void {
    // Tealium
    // console.log('CONSTRUCTOR Tealium:', this.tealium);
    // The tealium.view function will call the tealium.track function with 'view' as first param
    // Most tags support the 'view' event and many analytics tags also support the 'link' event
    this.tealium.view({ event_name: 'init' });
    // this.tealium.track('view', { event_name: 'init' });
  }
}
