import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { SempModule } from './app/semp.module';
import { environment } from './environments/semp.environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(SempModule)
  .catch(err => console.log(err));
