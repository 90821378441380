import { Routes } from '@angular/router';
var ɵ0 = {
    seo: {
        title: "Portal Empleo",
        meta: {
            description: "Portal Empleo Banco Santader",
            image: '/assets/images/logo_santander.png'
        }
    }
};
var routes = [
    {
        path: '',
        loadChildren: 'app/modules/main-container/main-container.module#MainContainerModule',
        data: ɵ0
    },
];
var SempRoutingModule = /** @class */ (function () {
    function SempRoutingModule() {
    }
    return SempRoutingModule;
}());
export { SempRoutingModule };
export { ɵ0 };
