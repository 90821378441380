import { NuarArchitectureService, NuarHttpMethod } from '@nuarsanesp/nuar-service-library';
import { of, forkJoin, Subject } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@nuarsanesp/nuar-service-library";
var CaaSService = /** @class */ (function () {
    /**
     * constructor
     * @param architectureService architectureService
     */
    function CaaSService(architectureService) {
        var _this = this;
        this.architectureService = architectureService;
        this.headerContentSource = new Subject();
        this.headerContent$ = this.headerContentSource.asObservable();
        this.footerContentSource = new Subject();
        this.footerContent$ = this.footerContentSource.asObservable();
        this.faqsContentSource = new Subject();
        this.faqsContent$ = this.faqsContentSource.asObservable();
        this.areasContentSource = new Subject();
        this.areasContent$ = this.areasContentSource.asObservable();
        this.testimoniosContentSource = new Subject();
        this.testimoniosContent$ = this.testimoniosContentSource.asObservable();
        this.galleriesContentSource = new Subject();
        this.galleriesContent$ = this.galleriesContentSource.asObservable();
        this.currentPageSource = new Subject();
        this.currentPage$ = this.currentPageSource.asObservable();
        this.caasConfig = this.architectureService.getConfigProperty('caas');
        this.biblioteca = this.caasConfig.biblioteca;
        this.apiURL = "" + this.caasConfig.baseUrl + this.caasConfig.basePath + "/" + this.caasConfig.biblioteca + "/";
        this.caasCommonContent = {};
        this.method = NuarHttpMethod.get;
        this.loadAllContent = function () {
            if (Object.keys(_this.caasCommonContent).length === 0) {
                return forkJoin(_this.getRESTData(_this.caasConfig.area_temas + "/header/header"), _this.getRESTData(_this.caasConfig.area_temas + "/footer/footer"), _this.getRESTData(_this.caasConfig.area_temas + "/faqs/faqs"), _this.getRESTData(_this.caasConfig.area_temas + "/areas/areas"), _this.getRESTData(_this.caasConfig.area_temas + "/testimonios/testimonios"), _this.getRESTData(_this.caasConfig.area_temas + "/galleries/galleries")).pipe(tap(function (_a) {
                    var header = _a[0], footer = _a[1], faqs = _a[2], areas = _a[3], testimonios = _a[4], galleries = _a[5];
                    _this.headerContentSource.next(header);
                    _this.footerContentSource.next(footer);
                    _this.faqsContentSource.next(faqs);
                    _this.areasContentSource.next(areas);
                    _this.testimoniosContentSource.next(testimonios);
                    _this.galleriesContentSource.next(galleries);
                    _this.caasCommonContent[_this.biblioteca + "-header"] = header;
                    _this.caasCommonContent[_this.biblioteca + "-footer"] = footer;
                    _this.caasCommonContent[_this.biblioteca + "-faqs"] = faqs;
                    _this.caasCommonContent[_this.biblioteca + "-areas"] = areas;
                    _this.caasCommonContent[_this.biblioteca + "-testimonios"] = testimonios;
                    _this.caasCommonContent[_this.biblioteca + "-galleries"] = galleries;
                }));
            }
            else {
                return of(true);
            }
        };
    }
    /**
     * reMap
     * @param response
     * @returns
     */
    CaaSService.prototype.reMap = function (response) {
        this.recursiveClean(response);
        response.sections.forEach(function (s) {
            var newSectionContents = [];
            s.section_contents.forEach(function (c) {
                if (c.sections && !c.main_content) {
                    newSectionContents.push({
                        main_content: {
                            order: c.order,
                            name: c.name,
                            description: c.description,
                            title: c.title,
                            antetitle: c.antetitle,
                            entradilla: c.entradilla,
                            body: c.body,
                            link: c.link,
                            image: c.image
                        },
                        sections: c.sections
                    });
                }
                else {
                    newSectionContents.push(c);
                }
            });
            s.section_contents = newSectionContents;
        });
        return response;
    };
    /**
     * recursiveClean
     * @param obj
     * @param parentKey
     */
    CaaSService.prototype.recursiveClean = function (obj, parentKey) {
        var _this = this;
        if (parentKey === void 0) { parentKey = null; }
        Object.keys(obj).forEach(function (k) {
            // Change string 'null' to real null
            if (obj[k] === 'null' || obj[k] === '' || obj[k] === null || obj[k] === undefined) {
                obj[k] = undefined;
                return;
            }
            // Remove <p> container tag from texts
            if (['title', 'body', 'entradilla', 'antentitle'].indexOf(k) !== -1 && obj[k].substring(0, 2) === '<p') {
                obj[k] = obj[k].substring(13, obj[k].length - 5);
            }
            switch (k) {
                // urls
                case 'url':
                case 'url_mobile':
                case 'url_tablet':
                case 'url_desktop':
                    var img = obj[k] || '';
                    if (!!img.match(/(.png|.jpg)/gi)) {
                        img = "" + _this.caasConfig.baseUrl + _this.caasConfig.imagesPath + img;
                    }
                    obj[k] = img;
                    break;
                // Convert string order numbers to real numbers
                case 'order':
                case 'section_order':
                    obj[k] = parseInt(obj[k], 10);
                    break;
                // Remove numbers characters from section_name
                case 'section_name':
                    obj[k] = obj[k].replace(/[0-9]/g, '');
                    break;
                // Recursive clean in objects arrays
                case 'sections':
                case 'section_contents':
                    obj[k].forEach(function (e) { return _this.recursiveClean(e); });
                    break;
            }
            // Recursive clean in objects
            if (typeof obj[k] === 'object' && !Array.isArray(obj[k])) {
                _this.recursiveClean(obj[k], k);
            }
        });
    };
    CaaSService.prototype.getRESTData = function (resource) {
        var _this = this;
        var url = "" + this.apiURL + resource + this.caasConfig.params;
        this.architectureService.showSpinner();
        return this.architectureService
            .connectorAsyncApi(url, this.method, {})
            // .connectorAsyncApi(url, this.method, {withCredentials: true})
            .pipe(map(function (res) {
            _this.architectureService.hideSpinner();
            if (res.length === 0) {
                return res;
            }
            if (Array.isArray(res)) {
                return _this.reMap(res[0]);
            }
            else {
                return _this.reMap(res);
            }
        }), shareReplay());
    };
    CaaSService.prototype.getPage = function (options) {
        var _this = this;
        var page = options.page;
        this.getRESTData(this.caasConfig.area_contenidos + "/" + page + "/" + page)
            .subscribe(function (resp) { return _this.currentPageSource.next(resp); });
    };
    CaaSService.prototype.getMainContent = function (options) {
        var page = options.page.toLowerCase();
        return of(this.caasCommonContent[this.biblioteca + "-" + page]);
    };
    CaaSService.prototype.setLibrary = function (lang) {
        this.biblioteca = this.caasConfig["biblioteca_" + lang];
        this.apiURL = "" + this.caasConfig.baseUrl + this.caasConfig.basePath + "/" + this.biblioteca + "/";
    };
    CaaSService.ngInjectableDef = i0.defineInjectable({ factory: function CaaSService_Factory() { return new CaaSService(i0.inject(i1.NuarArchitectureService)); }, token: CaaSService, providedIn: "root" });
    return CaaSService;
}());
export { CaaSService };
