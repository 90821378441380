/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./core/services/utag.service";
import * as i3 from "./semp.component";
import * as i4 from "@nuarsanesp/nuar-service-library";
var styles_SempComponent = [];
var RenderType_SempComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SempComponent, data: {} });
export { RenderType_SempComponent as RenderType_SempComponent };
export function View_SempComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_SempComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "semp-root", [], null, null, null, View_SempComponent_0, RenderType_SempComponent)), i0.ɵprd(512, null, i2.TealiumUtagService, i2.TealiumUtagService, []), i0.ɵdid(2, 114688, null, 0, i3.SempComponent, [i2.TealiumUtagService, i4.NuarArchitectureService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SempComponentNgFactory = i0.ɵccf("semp-root", i3.SempComponent, View_SempComponent_Host_0, {}, {}, []);
export { SempComponentNgFactory as SempComponentNgFactory };
