// Angular Core
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Nuar Service Library
import {
  NuarArchitectureService,
  NuarConfigService,
  NuarErrorService,
  NuarGlobalErrorHandler,
  NuarGlobalValidators,
  NuarLibraryModule,
  NuarModalWindowModule,
  RenderComponentService
} from '@nuarsanesp/nuar-service-library';


// Semp
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SempComponent } from './semp.component';
import { SempConstants } from './semp.constants';
import { SempRoutingModule } from './semp.routing.module';

// CaaS
import { CaaSService } from './core/services/caas.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/semp.environment';

// Architecture translate function
export function translateLoaderFactory(http: any) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Architecture config factory
export function configFactory(config: NuarConfigService) {
  const path = 'config.json';
  return () => config.load(path);
}

@NgModule({
  declarations: [
    SempComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SempRoutingModule,
    ReactiveFormsModule,
    NuarModalWindowModule,
    NuarLibraryModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  exports: [
  ],
  providers: [
    { provide: NuarGlobalValidators, useClass: NuarGlobalValidators },
    { provide: SempConstants, useClass: SempConstants },

    // register global error handler Architecture
    {
      provide: ErrorHandler,
      useClass: NuarGlobalErrorHandler
    },
    // ConfigService
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [NuarConfigService],
      multi: true
    }
    , RenderComponentService
    , NuarErrorService
    , NuarArchitectureService
    // CaaS Services
    , CaaSService
  ],
  bootstrap: [SempComponent]
})
export class SempModule {
  constructor(private readonly translate: TranslateService) {
    // Setting default language as Spanish
    translate.setDefaultLang('es');
  }
}
